import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Styles } from "../styles/styles";
import Home from "../pages/Home";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ToS from "../pages/ToS";
import Login from "../pages/Login";
import Register from "../pages/Register";
import PricingOnboarding from "../pages/PricingOnboarding";
import AutoUpdateOnboarding from "../pages/AutoUpdateOnboarding";
import NotFound from "../pages/NotFound";
import Dashboard from "../pages/Dashboard";

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/select-plan' element={<PricingOnboarding />} />
        <Route path='/select-auto-update' element={<AutoUpdateOnboarding />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-of-use' element={<ToS />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default Router;
