import { createContext, FC, ReactNode, useState } from 'react';

import { logOut } from '../firebase';
import { CURRENT_USER } from '../common/constants';

type AuthProviderProps = {
    children: ReactNode;
};

export const AuthContext = createContext<AuthContextType>({
    currentUser: null,
    setCurrentUserToStorage: null,
    signOut: null,
});

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const getCurrentUserFromStorage = () => {
        const userFromStorage = localStorage.getItem(CURRENT_USER);
        if (userFromStorage) {
            return JSON.parse(userFromStorage);
        }
        return null;
    };

    const [currentUser, setCurrentUser] = useState<Account | null>(getCurrentUserFromStorage());

    const setCurrentUserToStorage = (currentUser: Account) => {
        if (!currentUser) {
            return;
        }
        setCurrentUser(currentUser);
        localStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
    };

    const completeSignOut = async () => {
        localStorage.removeItem(CURRENT_USER);
        setCurrentUser(null);
        await logOut();
    };

    const value = {
        currentUser,
        setCurrentUserToStorage,
        signOut: completeSignOut,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
