import { updateDoc, collection, addDoc, query, where, orderBy, getDocs, doc, getDoc } from "firebase/firestore";
import axios from "axios";

import { firestoreDb } from "../firebase";
import { ACCOUNT_ID, ADVANCED_TEMPLATE, CH_SYNC, CREATED_AT, DESC, EMPTY_STRING, ENROLMENTS, EQUALS, IS_ACTIVE } from "../common/constants";

const { REACT_APP_29_ONE_TIME_PRICE, REACT_APP_4_99_RECURRING_PRICE } = process.env;

const paymentFuncsAPI = axios.create({
  baseURL: '',
  headers: {"Content-Type": "text/plain"}
});

const addEnrolment = async (userId: string, productType: string) : Promise<void> => {
  const enrolment: Enrolment = {
    id: EMPTY_STRING,
    productType: productType,
    accountId: userId,
    firstPaymentAt: 0,
    lastPaymentAt: 0,
    isActive: false,
    createdAt: Date.now(),
    canceledAt: 0,
  }
  try {
    const enrolmentRef = await addDoc(collection(firestoreDb, ENROLMENTS), enrolment);
    await updateDoc(enrolmentRef, { id: enrolmentRef.id });
  } catch (e) {
    console.error("Error adding enrolment: ", e);
  }
}

const getCheckoutSessionUrl = async (productType: string) : Promise<string | null> => {
  let res: any;
  try {
    if (productType === ADVANCED_TEMPLATE) {
      res = await paymentFuncsAPI.post("/", {"lookup_key": REACT_APP_29_ONE_TIME_PRICE});
    } else if (productType === CH_SYNC) {
      res = await paymentFuncsAPI.post("/", {"lookup_key": REACT_APP_4_99_RECURRING_PRICE});
    }
    if (res.status === 303) {
      const redirectUrl = res.headers['location'];
      if (redirectUrl) {
        return redirectUrl;
      } else {
        console.error('No Location header found in the response.');
      }
    } else {
      console.log('Response data:', res.data);
    }
  } catch (error) {
    console.error('Error:', error);
  }
  return null;
}

const getActiveEnrolments = async (userId: string) : Promise<Enrolment[] | null> => {
  try {
    const q = query(collection(firestoreDb, ENROLMENTS),
      where(ACCOUNT_ID, EQUALS, userId),
      where(IS_ACTIVE, EQUALS, true),
      orderBy(CREATED_AT, DESC),
    );
    const enrolmentsSnapshot = await getDocs(q);
    return enrolmentsSnapshot?.docs?.map(doc => doc.data() as Enrolment);
  } catch (e) {
    console.error("Error getting enrolment by userId: ", e);
  }
  return null;
}

const updateEnrolment = async (enrolmentObj: Enrolment) : Promise<void> => {
  try {
    const enrolmentRef = doc(firestoreDb, ENROLMENTS, enrolmentObj.id);
    const enrolment = await getDoc(enrolmentRef);
    if (enrolment.exists()) {
      await updateDoc(enrolmentRef, { ...enrolmentObj });
    }
  } catch (e) {
    console.error("Error updating enrolmentObj: ", e);
  }
}

const EnrolmentService = {
  addEnrolment,
  getActiveEnrolments,
  updateEnrolment,
  getCheckoutSessionUrl
}

export default EnrolmentService;
