import { lazy } from "react";
import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/AboutContent.json";
import PricingContent from "../content/PricingContent.json";
import OnboardingContent from "../content/OnboardingContent.json";
import PricingPage from "../components/Pricing";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../firebase";
import { DASHBOARD_PATH, SELECT_PLAN_PATH } from "../common/constants";

const MiddleBlock = lazy(() => import("../components/MiddleBlock"));
const Container = lazy(() => import("../common/Container"));
const ScrollToTop = lazy(() => import("../common/ScrollToTop"));
const ContentBlock = lazy(() => import("../components/ContentBlock"));

const Home = () => {

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (loggedInUser) {
        window.location.assign(SELECT_PLAN_PATH);
        return;
      }
    });
  }

  setUp();

  return (
    <Container>
      <ScrollToTop />
      <ContentBlock
        direction="right"
        title={IntroContent.title}
        content={IntroContent.text}
        showEmail={true}
        button={IntroContent.button}
        icon="https://firebasestorage.googleapis.com/v0/b/sunrise-e474d.appspot.com/o/koda%2Fmain_page.png?alt=media&token=f5149a11-4c8d-4feb-bd9f-185a782d0fb4"
        id="intro"
      />
      <ContentBlock
        direction="left"
        title={AboutContent.title}
        content={AboutContent.text}
        section={AboutContent.section}
        icon="img/svg/graphs.svg"
        id="features"
      />
      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
      />
      {/* <ContentBlock
        direction="right"
        title={PricingContent.title}
        content={PricingContent.text}
        icon="img/svg/product-launch.svg"
        id="mission"
      /> */}
      {/* <ContentBlock
        direction="left"
        title={OnboardingContent.title}
        content={OnboardingContent.text}
        icon="img/svg/waving.svg"
        id="product"
      /> */}
      <PricingPage id="pricing" />
    </Container>
  );
};

export default Home;
