import { FC, useState, ChangeEvent, useContext, useEffect, KeyboardEvent } from 'react';
import { UserCredential, User, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { CircularProgress } from '@mui/material';

import { auth, googleAuthProvider } from '../firebase';
import { AuthContext } from '../providers/AuthProvider';
import GoogleIcon from "../images/google.svg";
import { DASHBOARD_PATH, EMPTY_STRING, ENTER, HOME_PATH, LOGIN_RECOVER_PATH, REGISTER_PATH, SELECT_PLAN_PATH } from '../common/constants';
import UserService from '../services/userService';
import { emptyAccount } from '../services/emptyObjs';
import Container from '../common/Container';
import { Error, ButtonStretched, Input, LoginRegisterHeading, WhiteSpinnerSpan, LoginSignUpText, GoogleButton, GoogleIconImg, Span } from '../styles/styles';

const Login: FC = () => {
  const [email, setEmail] = useState(EMPTY_STRING);
  const [password, setPassword] = useState(EMPTY_STRING);
  const [loginError, setLoginError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [googleIsLoading, setGoogleIsLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { currentUser, setCurrentUserToStorage } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      window.location.assign(DASHBOARD_PATH);
    }
  }, []);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginError(false);
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginError(false);
    setPassword(event.target.value);
  }

  const handleSignUpClick = () => {
    setLoginError(false);
    window.location.assign(REGISTER_PATH);
    window.scrollTo(0, 0);
  }

  const handleForgotPasswordClick = async () => {
    setLoginError(false);
    window.location.assign(LOGIN_RECOVER_PATH);
    window.scrollTo(0, 0);
  };

  const existingUser = async (authUser: User) : Promise<boolean> => {
    if (authUser && setCurrentUserToStorage) {
      const account: Account | null = await UserService.getAccountById(authUser.uid);
      if (account) {
        setCurrentUserToStorage({ ...account });
        return true;
      }
    }
    return false;
  }

  const handleSignInGoogleClick = async () => {
    setGoogleIsLoading(true);
    try {
      const userCredential: UserCredential = await signInWithPopup(auth, googleAuthProvider);
      if (!userCredential) {
        return;
      }
      const authUser: User = userCredential.user;
      const userExists = await existingUser(authUser);
      if (!userExists) {
        const newAccount: Account = {
          ...emptyAccount,
          id: authUser.uid,
          email: authUser.email,
          createdAt: Date.now(),
          updatedAt: Date.now()
        };
        await UserService.addAccount(newAccount);
        setCurrentUserToStorage(newAccount);
        window.location.assign(SELECT_PLAN_PATH);
        return;
      }
      window.location.assign(DASHBOARD_PATH);
    } catch (error) {
      setLoginError(true);
      setErrorMessage("Cannot log you in with that account. Please try with another.");
    }
    setGoogleIsLoading(false);
  };

  const handleLoginClick = async () => {
    if (email === EMPTY_STRING || password === EMPTY_STRING) {
      setLoginError(true);
      setErrorMessage("Please enter your email and password.");
      return;
    }
    setLoading(true);
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
      const userExists: boolean = await existingUser(userCredential.user);
      if (!userExists) {
        setLoginError(true);
        setErrorMessage("Cannot find account with that email and password. Please make sure your email and password are correct.");  
        return;
      }
      window.location.assign(DASHBOARD_PATH);
    } catch (error) {
      setLoginError(true);
      setErrorMessage("Cannot find account with that email and password. Please make sure your email and password are correct.");
    }
    setLoading(false);
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ENTER) {
      handleLoginClick()
    }
  };

  return (
    <Container>
      <div style={{ marginBottom: "250px", marginTop: "50px" }}>
        <LoginRegisterHeading>Sign In</LoginRegisterHeading>
          <form>
            {!googleIsLoading && <>
              <Input type='email' placeholder='Email' value={email} onChange={handleEmailChange} required />
              { loginError && <Error style={{ marginRight: "auto", marginLeft: "auto" }}>{errorMessage}</Error> }
              <Input type='password' placeholder='Password' value={password} onChange={handlePasswordChange} onKeyDown={handleEnterPress} required />
              <ButtonStretched onClick={handleLoginClick} style={{ marginBottom: "20px" }}>
                {loading &&
                  <WhiteSpinnerSpan>
                    <CircularProgress style={{ width: "30px", height: "30px", color: "#fff" }} />
                  </WhiteSpinnerSpan>
                }
                {!loading && <>Sign In</>}
              </ButtonStretched>
              <LoginSignUpText style={{ fontSize: "15px" }}>or</LoginSignUpText></>
            }
            <GoogleButton onClick={handleSignInGoogleClick}>
              <GoogleIconImg src={GoogleIcon} />
              {googleIsLoading ?
                <span style={{ width: "180px", textAlign: "center" }}>
                  <CircularProgress style={{ width: "30px", height: "30px" }} />
                </span>
                :
                <>Continue with Google</>
              }
            </GoogleButton>
          </form>
          <LoginSignUpText>Don't have an account? <Span onClick={handleSignUpClick}>Sign up</Span></LoginSignUpText>
          <LoginSignUpText><Span onClick={handleForgotPasswordClick}>Forgotten password?</Span></LoginSignUpText>
        </div>
    </Container>
  );
};

export default Login;
