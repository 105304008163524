import { signOut } from "firebase/auth";

import { auth } from './index';

export const logOut = async () : Promise<void> => {
  try {
    await signOut(auth);
  } catch (e) {
    console.error("Firebase Error signing out: ", e);
  }
}
