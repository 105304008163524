export const EMPTY_STRING = "";
export const CURRENT_USER = "currentUser";
export const HOME_PATH = "/"
export const LOGIN_PATH = "/login"
export const REGISTER_PATH = "/register"
export const LOGIN_RECOVER_PATH = "/login/recover"
export const ONBOARDING_PATH = "/onboarding"
export const SELECT_SYNC_PATH = "/select-auto-update"
export const SELECT_PLAN_PATH = "/select-plan"
export const DASHBOARD_PATH = "/dashboard"
export const ENTER = "/enter"
export const USERNAME = "username"
export const NOT_EQUALS = "!=";
export const EQUALS = "==";
export const ACCOUNT_ID = "accountId";
export const UPDATED_AT = "updatedAt";
export const CREATED_AT = "createdAt";
export const DESC = "desc";
export const ASC = "asc";
export const OWNER_ID = "ownerId";
export const EMAIL = "email";

export const NOTION_DATABASES = "notion_databases";
export const NOTION_WORKSPACES = "notion_workspaces";
export const ENROLMENTS = "enrolments";
export const ACCOUNTS = "accounts";
export const IS_ACTIVE = "isActive";

export const ADVANCED_TEMPLATE = "advancedTemplate";
export const BASIC_TEMPLATE = "basicTemplate";
export const CUSTOM_TEMPLATE = "customTemplate";
export const CH_SYNC = "ch_sync";
