import { useContext, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';

import { AuthContext } from '../providers/AuthProvider';
import { ADVANCED_TEMPLATE, CH_SYNC, DASHBOARD_PATH, EMPTY_STRING, LOGIN_PATH } from '../common/constants';
import Container from '../common/Container';
import { auth } from '../firebase';
import { MiddleBlockSection } from '../components/MiddleBlock/styles';
import { ListItems } from '../styles/styles';
import { StyledButton } from '../common/Button/styles';
import EnrolmentService from '../services/enrolmentService';

const AutoUpdateOnboarding = () => {
  const { signOut } = useContext(AuthContext);
  const [userId, setUserId] = useState<string>(EMPTY_STRING);

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
      setUserId(loggedInUser.uid);
    });
  }

  setUp();

  const handleSkipClick = async () => {
    if (userId) {
      const latestActiveEnrolments: Enrolment[] = await EnrolmentService.getActiveEnrolments(userId);
      const lastestActiveType: string = latestActiveEnrolments?.at(0)?.productType;
      if (lastestActiveType === ADVANCED_TEMPLATE) {
        const url: string = await EnrolmentService.getCheckoutSessionUrl(lastestActiveType);
        window.location.assign(url);
        return;
      }
      window.location.assign(DASHBOARD_PATH);
    }
  }

  const handleAutoClick = async () => {
    if (userId) {
      await EnrolmentService.addEnrolment(userId, CH_SYNC);
      const url: string = await EnrolmentService.getCheckoutSessionUrl(CH_SYNC);
      window.location.assign(url);
    }
  }

  return (
    <Container>
      <MiddleBlockSection style={{ display: "grid", marginBottom: "250px", marginTop: "50px" }}>
      <p style={{ marginBottom: "15px" }}>Add automatic updates from Companies House?</p>
      <ListItems>Just add the company numbers and Koda will automatically complete all the other details for all your companies.</ListItems>
      <div>
      <StyledButton style={{ boxShadow: "none", marginRight: "10px" }} onClick={handleAutoClick}>Automatically update</StyledButton>
      <StyledButton style={{ color: "rgb(5, 5, 5)", backgroundColor: "rgb(228, 230, 235)", boxShadow: "none" }} onClick={handleSkipClick}>
        I'll do it manually
      </StyledButton>
      </div>
    </MiddleBlockSection>
    </Container>
  );
};

export default AutoUpdateOnboarding;
