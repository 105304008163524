import { EMPTY_STRING } from "../common/constants";

export const emptyAccount: Account = {
  id: EMPTY_STRING,
  username: EMPTY_STRING,
  name: EMPTY_STRING,
  email: EMPTY_STRING,
  stripeCustomerId: EMPTY_STRING,
  createdAt: 0,
  updatedAt: 0
}
