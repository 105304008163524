'use client';
import { CardContent } from '@mui/material';
import { Slide } from 'react-awesome-reveal';
import { ContentWrapper, MiddleBlockSection } from './MiddleBlock/styles';
import { Col, Row } from 'antd';

import { Button, Card, CardsDiv, CheckCircle, ListItems, ListRow, Price, Text } from '../styles/styles';
import { REGISTER_PATH } from '../common/constants';

const Pricing = ({id}) => {

  return (
    <MiddleBlockSection id={id}>
      <Slide direction="right" triggerOnce>
        <Row justify="center" align="middle">
          <ContentWrapper>
            <Col lg={24} md={24} sm={24} xs={24}>
              <h6>Pricing</h6>
              <CardsDiv>
                <Card>
                  <CardContent>
                    <p>Template</p>
                    <Text><Price>£29</Price> one-time fee</Text>
                    <Button style={{ marginTop: "30px" }} onClick={() => window.location.assign("https://buy.stripe.com/9AQ6pef0r4ml4IE289")}>Select</Button>
                    <ListItems style={{ textAlign: "center" }}>Template includes:</ListItems>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Team task assignment</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Task tracking</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Client onboarding</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Task Automation</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Communication log</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Quarterly bookkeeping reminders</ListItems>
                    </ListRow>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <p>Template + Sync</p>
                    <Text style={{ marginBottom: "0px" }}><Price>£29</Price> one-time fee</Text>
                    <Text style={{ marginBottom: "0px" }}>Plus <span style={{ color: "rgb(0, 0, 0)", fontWeight: "700" }}>£4.99</span> / month</Text>
                    <Button style={{ marginTop: "20px" }} onClick={() => window.location.assign("https://buy.stripe.com/eVafZOg4vdWVdfa5kk")}>Select</Button>
                    <ListItems style={{ textAlign: "center" }}>Template plus:</ListItems>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Automatic updates from Companies House</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>24/7 support</ListItems>
                    </ListRow>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <p>Custom</p>
                    <Text>We will help you design and build custom templates for your specific needs.</Text>
                    <a href="mailto:team@happykoda.com">
                      <Button style={{ marginTop: "5px" }}>Email us</Button>
                    </a>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>One-on-one call support</ListItems>
                    </ListRow>
                    <ListRow>
                      <CheckCircle />
                      <ListItems>Tailored templates for your business needs</ListItems>
                    </ListRow>
                  </CardContent>
                </Card>
              </CardsDiv>
            </Col>
          </ContentWrapper>
        </Row>
      </Slide>
    </MiddleBlockSection>
  );
}

export default Pricing;
