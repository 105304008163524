import { useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { AuthContext } from '../providers/AuthProvider';
import { EMPTY_STRING, LOGIN_PATH } from '../common/constants';
import { auth } from '../firebase';
import Container from '../common/Container';
import { Card } from 'antd';
import { Button } from '../common/Button';
import { MiddleBlockSection } from '../components/MiddleBlock/styles';
import { Slide } from 'react-awesome-reveal';
import { StyledInput } from '../common/Input/styles';
import { StyledButton } from '../common/Button/styles';
import { useParams, useSearchParams } from 'react-router-dom';
import NotionWorkspaceService from '../services/notionWorkspaceService';

const { REACT_APP_NOTION_AUTH_URL } = process.env;

const Dashboard = () => {
  const { signOut } = useContext(AuthContext);
  const [userId, setUserId] = useState<string>(EMPTY_STRING);
  const [databases, setDatabases] = useState<NotionDatabase[]>([]);

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const setUp = () => {
    onAuthStateChanged(auth, (loggedInUser: User) => {
      if (!loggedInUser) {
        signOut();
        window.location.assign(LOGIN_PATH);
        return;
      }
      setUserId(loggedInUser.uid);
    });
  }

  setUp();

  // Create form to add DB

  const fetchDatabases = async () => {
    // setDatabases(notionDatabases);
  }

  const fetchWorkspaces = async () => {
  }

  const handleCheckoutClick = async () => {
    // Get details of notion DB
    // Create NotionDatabase record
    // Create Enrolment
    // const url: string = await EnrolmentService.getCheckoutSessionUrl(ADVANCED_TEMPLATE);
    // window.location.assign(url);
  }

  const handleChange = async () => {
    // setDatabases(notionDatabases);
  }

  const handleConnectToNotion = () => {
    window.location.assign(REACT_APP_NOTION_AUTH_URL);
  }

  const getAccessToken = async (codeInURL) => {
    // const res: boolean = await NotionWorkspaceService.getAccessTokenGivenCode(codeInURL);
    // if (res) {
    //   fetchWorkspaces();
    // }
  }

  useEffect(() => {
    fetchDatabases();
    fetchWorkspaces();
  }, [])

  useEffect(() => {
    if (code) {
      getAccessToken(code);
    }
  }, [code])

  // const query = new URLSearchParams(window.location.search);
  // if (query.get('success')) {
  //   const sessionId = query.get('session_id');
  // }

  return (
    <Container>
      <MiddleBlockSection style={{ marginBottom: "250px" }}>
        <Slide direction="right" triggerOnce>
          {databases?.length > 0 && databases.map(db => (
            <Card>
              <div>{db.title}</div>
              <div>{db.status}</div>
              <Button>Sync with Companies House</Button>
            </Card>
          ))}
          <div>
            <p style={{ marginBottom: "20px" }}>Connect to your Notion DB</p>
            <StyledButton onClick={handleConnectToNotion}>Connect</StyledButton>
            <p style={{ marginBottom: "20px" }}>Add Companies House sync to a Notion Database</p>
            <StyledInput type="text" name="name" placeholder="Database Id" value={""} onChange={handleChange} />
            <StyledButton onClick={handleCheckoutClick}>Go to checkout</StyledButton>
          </div>
        </Slide>
      </MiddleBlockSection>
    </Container>
  );
};

export default Dashboard;
